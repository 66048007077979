import {ProcessInfo, ProgramSelectionStatus} from "../@types.ts";
import React from "react";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"; // ProgramSelector renders the selection state for one program and all its
import {ProcessPprofAddresses} from "../helpers/pprofAddresses.ts";
import {PprofAddressInput} from "./PprofAddressInput.tsx";
import Chip from "@mui/material/Chip";

type ProgramSelectorProps = {
  programName: string;
  selectionStatus: ProgramSelectionStatus;
  // The processes currently reported by agents that correspond to this program.
  processes: ProcessInfo[];
  onProgramToggle: (selected: boolean) => void;
  onProcessToggle: (process: ProcessInfo, selected: boolean) => void;

  // The pprof addresses for the processes. If specified, textboxes are rendered
  // for each process monitored by an agent (as opposed to monitored through the
  // side-eye-go library) to allow the user to input a pprof address.
  pprofInformation?: ProcessPprofAddresses;
};

// ProgramSelector renders the selection state for one program and all its
// processes.
export function ProgramSelector(
  props: ProgramSelectorProps,
): React.JSX.Element {
  const selectionStatus = props.selectionStatus;
  const pprofInformation = props.pprofInformation;

  return (
    <>
      <ListItem key={props.programName} sx={{px: 0}}>
        <ListItemIcon>
          <Checkbox
            checked={selectionStatus.type == "all"}
            indeterminate={selectionStatus.type == "some"}
            onChange={(e) => props.onProgramToggle(e.target.checked)}
          />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body3">Program: {props.programName}</Typography>
        </ListItemText>
      </ListItem>

      <ListItem>
        <List dense={true} className="show-list-style" sx={{width: "100%"}}>
          {props.processes.map((process: ProcessInfo) => {
            let binaryName = process.binary.binaryName;

            if (process.binary.unknown) {
              binaryName += " (unknown binary)";
            }
            const [baseAddress, overrideAddress] =
              pprofInformation?.getProcessAddress(process.processToken) ?? [
                undefined,
                undefined,
              ];

            return (
              <ListItem key={process.processToken} sx={{width: "100%"}}>
                <ListItemIcon>
                  <Checkbox
                    onChange={(e) =>
                      props.onProcessToggle(process, e.target.checked)
                    }
                    // The process is selected either explicitly through the
                    // whole program, or explicitly.
                    checked={
                      selectionStatus.type == "all" ||
                      (selectionStatus.type == "some" &&
                        selectionStatus.selectedProcs.some(
                          (v) => v.processToken === process.processToken,
                        ))
                    }
                  />
                </ListItemIcon>
                <ListItemText sx={{width: "100%"}}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography variant="body3">
                      Process: PID: {process.processID}, host:{" "}
                      {process.host.hostname}, binary: {binaryName}
                    </Typography>
                    {process.isLibrary && (
                      <Tooltip
                        title={
                          "The process is monitored through the side-eye-go library."
                        }
                      >
                        <Chip
                          label={"side-eye-go"}
                          variant={"outlined"}
                          color={"info"}
                          size={"small"}
                          sx={{fontSize: 8, p: 0}}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                </ListItemText>
                {pprofInformation && !process.isLibrary && (
                  <Box
                    display="flex"
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <PprofAddressInput
                      defaultAddress={baseAddress}
                      address={overrideAddress}
                      updatePprofAddress={(value) => {
                        pprofInformation.setProcessAddress(
                          process.processToken,
                          value,
                        );
                      }}
                    />
                  </Box>
                )}
              </ListItem>
            );
          })}
        </List>
      </ListItem>
    </>
  );
}
