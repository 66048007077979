import type {FunctionName} from "@graphql/graphql.ts";
import React, {Key, ReactNode} from "react";
import {Box, Button, ListItem} from "@mui/material";
import {
  functionAutocompletionOption,
  moduleAutocompletionOption,
  packageAutocompletionOption,
  packagePrefixAutocompleteOption,
  typeAutocompletionOption,
} from "@components/tables/util.tsx";

export class autocompleteOptionNewFunction {
  type = "newFunction" as const;
  funcName: FunctionName;

  constructor(funcName: FunctionName) {
    this.funcName = funcName;
  }

  render = (
    props: React.HTMLAttributes<HTMLLIElement> & {key: Key},
  ): ReactNode => {
    // The "key" property needs to be passed explicitly. See
    // https://github.com/mui/material-ui/issues/39833.
    const {key, ...optionProps} = props;
    return (
      <ListItem key={key} {...optionProps}>
        {this.funcName.QualifiedName}
      </ListItem>
    );
  };
}

export type autocompleteOption =
  | autocompleteOptionExistingEventWrapper
  | autocompleteOptionNewFunction
  | autocompleteOptionSelectBinary;

type autocompleteOptionExistingEvent =
  | functionAutocompletionOption
  | packageAutocompletionOption
  | typeAutocompletionOption
  | moduleAutocompletionOption
  | packagePrefixAutocompleteOption;

export class autocompleteOptionExistingEventWrapper {
  opt: autocompleteOptionExistingEvent;
  // Keep track of the module that this option belongs to. This is needed to
  // match the options against a selected module.
  moduleName: string;

  constructor(opt: autocompleteOptionExistingEvent, moduleName: string) {
    this.opt = opt;
    this.moduleName = moduleName;
  }

  get type(): string {
    return this.opt.type;
  }
}

// autocompleteOptionSelectBinary represents an option that renders as a button
// allowing the user to trigger the binary selection dialog. We tell the
// Autocomplete that this option is disabled, so that it's not possible to
// select it; you can only interact with it by pressing the button we render
// inside it.
export class autocompleteOptionSelectBinary {
  type = "selectBinary" as const;
  readonly promptForBinarySelection: () => Promise<void>;

  constructor(promptForBinarySelection: () => Promise<void>) {
    this.promptForBinarySelection = promptForBinarySelection;
  }

  render = (
    props: React.HTMLAttributes<HTMLLIElement> & {key: Key},
  ): ReactNode => {
    // The "key" property needs to be passed explicitly. See
    // https://github.com/mui/material-ui/issues/39833.
    const {key, ..._optionProps} = props;
    return (
      // NOTE: We don't pass {...optionProps} to the ListItem, because we don't
      // want the CSS classes for a disabled option to apply.
      <ListItem key={key}>
        <Box onClick={(e) => e.stopPropagation()}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              void this.promptForBinarySelection();
            }}
          >
            Select binary
          </Button>
          to see new function suggestions
        </Box>
      </ListItem>
    );
  };
}
