import {PprofAddress} from "../@types";

function isValidURL(address: string): boolean {
  try {
    const url = new URL(address);
    return url.protocol == "http:" || url.protocol == "https:";
  } catch (_) {
    return false;
  }
}

function makePprofAddress(value: string): PprofAddress {
  const asNumber = parseInt(value);
  let url: string | undefined;
  if (!isNaN(asNumber) && asNumber > 0 && asNumber < 64 << 10) {
    url = `http://localhost:${asNumber}/debug/pprof/`;
  } else if (isValidURL(value)) {
    url = value;
  }
  if (url === undefined) {
    return {type: "invalid", value: value};
  }
  return {type: "address", value: value, url: url};
}

export const librarySymbol = Symbol("library");

export class ProcessPprofAddresses {
  // Maps from process token to pprof address.
  // baseAddresses come from the process pprof rules.
  private readonly baseAddresses: Map<string, string | typeof librarySymbol>;
  // addressOverrides come from the user input in the textbox.
  private readonly addressOverrides: Map<string, PprofAddress>;

  // updateAddress is a callback that is called when the user updates the pprof
  // address for one process.
  private readonly updateAddress: (
    processToken: string,
    address: string | undefined,
  ) => void;

  constructor(
    pprofAddresses: Map<string, string | typeof librarySymbol>,
    addressOverrides: Map<string, string>,
    updateAddress: (processToken: string, address: string | undefined) => void,
  ) {
    this.baseAddresses = new Map();
    for (const [processToken, address] of pprofAddresses.entries()) {
      this.baseAddresses.set(processToken, address);
    }
    this.addressOverrides = new Map();
    for (const [processToken, address] of addressOverrides.entries()) {
      this.addressOverrides.set(processToken, makePprofAddress(address));
    }
    this.updateAddress = updateAddress;
  }

  getProcessAddress(
    processToken: string,
  ): [PprofAddress | undefined, PprofAddress | undefined] {
    let baseAddress: PprofAddress | undefined = undefined;
    const addr = this.baseAddresses.get(processToken);
    if (addr != undefined) {
      if (addr == librarySymbol) {
        baseAddress = {type: "library"};
      } else {
        baseAddress = makePprofAddress(addr);
      }
    }
    let override: PprofAddress | undefined = undefined;
    if (this.addressOverrides.has(processToken)) {
      override = this.addressOverrides.get(processToken);
    }
    return [baseAddress, override];
  }

  setProcessAddress(processToken: string, address: string | undefined) {
    if (address == "") {
      throw new Error("address must not be empty");
    }
    this.updateAddress(processToken, address);
  }

  validOverrideURLs(): {processToken: string; url: string}[] {
    return Array.from(
      this.addressOverrides
        .entries()
        .map(([processToken, address]) => {
          if (address.type == "address") {
            return {processToken, url: address.url};
          }
          return undefined;
        })
        .filter((a) => a !== undefined),
    );
  }
}
