// EventsTreeView renders a tree with all functions that have events specs,
// together with the modules, packages and types parents.
import React, {Key, ReactNode, useState} from "react";
import {useApolloClient} from "@apollo/client";
import {useConfirmationDialog} from "../../../providers/confirmation-dialog";
import {FunctionSpecEditor} from "../../../util/function-spec-editing";
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import {
  Box,
  Button,
  Checkbox,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import {FunctionTableEditor} from "../../../components/FunctionTableEditor";
import type {
  FunctionName,
  FunctionSpec,
  FunctionStartEventSpec,
} from "../../../__generated__/graphql";
import {exhaustiveCheck} from "../../../util/util";
import {
  EventInfoSelection,
  EventsGroupSelection,
  ModuleEventsSelection,
  PackageEventsSelection,
  TypeEventsSelection,
} from "../types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CodeIcon from "@mui/icons-material/Code";
import DataArrayIcon from "@mui/icons-material/DataArray";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {autocompleteOptionExistingEventWrapper} from "./types";

export default function EventsTreeView(props: {
  tree: EventsTree;
  // The IDs of nodes to expand. These IDs need to correspond to what nodeID()
  // returns for nodes in `tree`.
  expandedNodeIDs: string[];
  // Callback used when the list of expanded nodes changes.
  setExpandedNodeIDs: (expandedNodeIDs: string[]) => void;
  checkedNodes: EventsGroupSelection[];
  setCheckedNodes: (checkedNodes: EventsGroupSelection[]) => void;
  // noEventsMessage is the message rendered if tree is empty.
  noEventsMessage: string;

  // binaryID is either the ID of the binary to be used when listing available
  // variables, or a function to call to get the binary ID. If it is a function,
  // the expectation is that calling the function will eventually cause a
  // re-render of this component with the binary ID set to a string.
  binaryID: string | (() => void);
}): React.JSX.Element {
  const nodes: TreeNode[] = props.tree.modules.map(TreeNode.fromModule);
  for (const n of nodes) {
    linkParents(n, undefined /* parent */);
  }
  const nodeStatuses = new Map<string, nodeStatus>();
  for (const n of nodes) {
    computeBottomUpStatus(
      n,
      false /* parentChecked */,
      props.checkedNodes.map((n) => n.nodeID()),
      nodeStatuses,
    );
  }

  function toggleNode(node: TreeNode, checked: boolean) {
    let newCheckedNodes: EventsGroupSelection[];
    if (checked) {
      newCheckedNodes = [...props.checkedNodes];
      newCheckedNodes.push(node.events.toSelection());
    } else {
      // First, uncheck the node and all its children, recursively.
      const childIDs = node.treeIDs();
      newCheckedNodes = props.checkedNodes.filter((n) => {
        return !childIDs.includes(n.nodeID());
      });

      const fixup = () => {
        if (node.parent == undefined) {
          return;
        }
        let parent: TreeNode = node.parent;
        while (parent != undefined) {
          if (props.checkedNodes.some((n) => n.nodeID() == parent.id)) {
            // I've found a parent that was checked. Uncheck it, and check all
            // its children except the child through which we got here.
            newCheckedNodes = props.checkedNodes.filter(
              (n) => n.nodeID() != parent.id,
            );

            for (const c of parent.children) {
              if (c.id == node.id) {
                // Ignore our node.
                continue;
              }
              const childChecked = props.checkedNodes.some(
                (n) => n.nodeID() == c.id,
              );
              if (!childChecked) {
                newCheckedNodes.push(c.events.toSelection());
              }
            }
          }
          if (parent.parent == undefined) {
            return;
          }
          parent = parent.parent;
          node = node.parent!;
        }
      };

      // Now walk to the root and, whenever we find a node that's checked, we
      // replace it with all its children except the child through which we
      // got there.
      fixup();
    }

    props.setCheckedNodes(newCheckedNodes);
  }

  // A callback to hide the variables for the node that is currently showing
  // variables, if any.
  const [hidePreviousVars, setHidePreviousVars] = useState<
    (() => void) | undefined
  >(undefined);

  // onNodeVarsToggled is called whenever the vars of a node are toggled. It ensures that
  // only one node's variables are toggled at a time.
  function onNodeVarsToggled(hide: (() => void) | undefined) {
    if (hidePreviousVars != undefined) {
      hidePreviousVars();
    }
    setHidePreviousVars(() => hide);
  }

  return (
    <SimpleTreeView
      disableSelection={true}
      slots={{expandIcon: ChevronRightIcon, collapseIcon: ExpandMoreIcon}}
      expandedItems={props.expandedNodeIDs}
      onExpandedItemsChange={(_event, expandedNodeIDs: string[]) => {
        props.setExpandedNodeIDs(expandedNodeIDs);
      }}
    >
      {nodes.length == 0 ? (
        <Box key={"no-fields"}>
          <Typography variant={"explanation"}>
            {props.noEventsMessage}
          </Typography>
        </Box>
      ) : (
        <>
          {nodes.map((n) => (
            <EventsTreeNode
              key={n.id}
              node={n}
              nodeStatuses={nodeStatuses}
              expandedNodeIDs={props.expandedNodeIDs}
              setExpandedNodeIDs={props.setExpandedNodeIDs}
              toggleNode={toggleNode}
              onVarsToggle={(hide: (() => void) | undefined) =>
                onNodeVarsToggled(hide)
              }
              binaryID={props.binaryID}
            />
          ))}
        </>
      )}
    </SimpleTreeView>
  );
}

function computeBottomUpStatus(
  n: TreeNode,
  parentChecked: boolean,
  checkedNodeIDs: string[],
  m: Map<string, nodeStatus>,
) {
  const nodeCheckedExplicitly = checkedNodeIDs.includes(n.id);
  for (const c of n.children) {
    computeBottomUpStatus(
      c,
      parentChecked || nodeCheckedExplicitly,
      checkedNodeIDs,
      m,
    );
  }
  if (parentChecked || nodeCheckedExplicitly) {
    m.set(n.id, "all");
    return;
  }
  if (n.children.length == 0) {
    m.set(n.id, "none");
    return;
  }
  if (n.children.every((c) => m.get(c.id) == "all")) {
    m.set(n.id, "all");
  } else if (
    n.children.some((c) => {
      const childStatus = m.get(c.id);
      return childStatus == "some" || childStatus == "all";
    })
  ) {
    m.set(n.id, "some");
  } else {
    m.set(n.id, "none");
  }
}

function EventsTreeNode(props: {
  node: TreeNode;
  nodeStatuses: Map<string, nodeStatus>;
  // The IDs of nodes to expand. These IDs need to correspond to what nodeID()
  // returns for nodes in `tree`.
  expandedNodeIDs: string[];
  // Callback used when the list of expanded nodes changes.
  setExpandedNodeIDs: (expandedNodeIDs: string[]) => void;
  toggleNode: (node: TreeNode, checked: boolean) => void;
  // onVarsToggle is called when the user clicks the "Show/hide captured
  // variables" button. If the node's variables are toggled to shown, then
  // `hide` is a callback that can be used to hide the variables. If the node's
  // variables are toggled to hidden, then `hide` is undefined. EventsTreeNode
  // renders the function's variables on its own, but the parent might still
  // want to know that some variables are being shown.
  onVarsToggle: (hide: (() => void) | undefined) => void;

  // binaryID is either the ID of the binary to be used when listing available
  // variables, or a function to call to get the binary ID. If it is a function,
  // the expectation is that calling the function will eventually cause a
  // re-render of this component with the binary ID set to a string.
  binaryID: string | (() => void);
}): React.JSX.Element {
  const client = useApolloClient();
  const showConfirmationDialog = useConfirmationDialog();
  const [showVars, setShowVarsInner] = useState(false);
  const setShowVars = (val: boolean) => {
    setShowVarsInner(val);
    if (val) {
      props.onVarsToggle(() => setShowVarsInner(false));
    } else {
      props.onVarsToggle(undefined);
    }
  };

  const onNodeToggle = (checked: boolean, node: TreeNode): void => {
    props.toggleNode(node, checked);
  };

  const node = props.node;
  const status = props.nodeStatuses.get(props.node.id);
  if (status == undefined) {
    throw new Error(`bottom-up status not found for node ${props.node.id}`);
  }
  const ev = node.events;
  const eventSpec = ev.type == "func" ? ev.spec : undefined;

  const eventSpecEditor =
    ev.type == "func"
      ? new FunctionSpecEditor(
          "event",
          ev.func,
          eventSpec,
          showConfirmationDialog,
          client,
        )
      : undefined;

  return (
    <TreeItem
      key={node.name}
      itemId={node.id}
      label={
        <span>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Checkbox
              checked={status == "all"}
              indeterminate={status == "some"}
              onChange={(event) => onNodeToggle(event.target.checked, node)}
              onClick={(e) => e.stopPropagation()} // Don't expand/collapse the whole TreeItem when clicking the checkbox.
            />
            {node.type == "module" && <CodeIcon />}
            {node.type == "type" && <DataArrayIcon />}
            {/*TODO: don't use the same icon for function and package */}
            {node.type == "package" && <DataObjectIcon />}
            {node.type == "function" && <DataObjectIcon />}
            <Typography>{node.name}</Typography>
            {ev.type == "func" &&
              (showVars ? (
                <Button onClick={() => setShowVars(false)}>
                  Hide captured variables
                </Button>
              ) : (
                <Button onClick={() => setShowVars(true)}>
                  Show captured variables
                </Button>
              ))}
          </Stack>
          {ev.type == "func" && showVars && (
            <FunctionTableEditor
              labels={{
                variablesLabel: "Variables included in the event",
                variablesTooltip: `The set of variables to collect and expressions to evaluate
                    whenever this function starts executing.`,
                capturedExprTooltip: `The name of the column representing this captured
                    expression in the function's events table.`,
                tableNameTooltip: `The table name under which this function's event table is stored in
                    the events database. This is the table name to use in SQL queries.`,
                extraColsTooltip: `Extra columns for the function's events table, in addition to 
                    the columns defined implicitly by the captured variables above. These 
                    extra columns are defined using SQL expressions (commonly using JSONPath) evaluated on top 
                    of the implicit columns (i.e. the expressions can reference these implicit columns; 
                    the names of implicit columns containing dots should be quoted like "myVar.myField").`,
              }}
              binaryID={props.binaryID}
              specEditor={eventSpecEditor!}
              tableSpec={eventSpec}
              funcQualifiedName={ev.func.QualifiedName}
              paramsOnly={true}
            />
          )}
        </span>
      }
    >
      {node.children.map((n: TreeNode) => (
        <EventsTreeNode
          key={n.id}
          node={n}
          nodeStatuses={props.nodeStatuses}
          toggleNode={props.toggleNode}
          onVarsToggle={props.onVarsToggle}
          expandedNodeIDs={props.expandedNodeIDs}
          setExpandedNodeIDs={props.setExpandedNodeIDs}
          binaryID={props.binaryID}
        />
      ))}
    </TreeItem>
  );
}

function linkParents(node: TreeNode, parent: TreeNode | undefined) {
  node.parent = parent;
  for (const c of node.children) {
    linkParents(c, node);
  }
}

type nodeStatus = "all" | "some" | "none";

export class EventsTree {
  modules: ModuleEvents[];

  constructor(modules: ModuleEvents[] = []) {
    this.modules = modules;
  }

  addFunctionStartEvent = (
    event: FunctionStartEventSpec,
    moduleName: string,
    modulePkgPath: string,
    functionSpec: FunctionSpec,
  ) => {
    let module = this.modules.find((m) => m.moduleName == moduleName);
    if (!module) {
      const newModule = new ModuleEvents(moduleName, modulePkgPath, []);
      this.modules.push(newModule);
      module = newModule;
    }
    let pkg = module.packages.find(
      (p) => p.packageName == functionSpec.funcName.Package,
    );
    if (!pkg) {
      const newPkg = new PackageEvents(functionSpec.funcName.Package, [], []);
      module.packages.push(newPkg);
      pkg = newPkg;
    }

    if (functionSpec.funcName.Type) {
      let type = pkg.types.find(
        (t) => t.typeName == functionSpec.funcName.Type,
      );
      if (!type) {
        const newType = new TypeEvents(
          functionSpec.funcName.Type,
          pkg.packageName,
          [],
        );
        pkg.types.push(newType);
        type = newType;
      }
      type.events.push(new EventInfo(event, functionSpec.funcName));
    } else {
      pkg.functionEvents.push(new EventInfo(event, functionSpec.funcName));
    }
  };

  // filter takes a selected autocomplete option or a string, and returns the
  // filtered tree containing the nodes that match the query and their children.
  // If the query is a string, all nodes that contain that string are matches.
  filter = (
    opt: autocompleteOptionExistingEventWrapper | string,
  ): filterResults => {
    if (typeof opt == "string") {
      const newModules: ModuleEvents[] = [];
      const matchingNodeIDs: string[] = [];
      for (const e of this.modules) {
        const res = e.filter(opt);
        if (res) {
          newModules.push(res.filtered);
          matchingNodeIDs.push(...res.matchingNodeIDs);
        }
      }

      return {
        filtered: new EventsTree(newModules),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    const module = this.modules.find((m) => m.moduleName == opt.moduleName);
    if (module == undefined) {
      throw new Error(`Module ${opt.moduleName} not found`);
    }

    const matchingNodeIDs = [module.nodeID()];

    const inner = opt.opt;

    if (inner.type == "module") {
      return {
        filtered: new EventsTree([module]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "packagePrefix") {
      const newModules: ModuleEvents[] = [];
      for (const m of this.modules) {
        const newPackages: PackageEvents[] = [];
        for (const p of m.packages) {
          if (p.packageName.startsWith(inner.pkg)) {
            newPackages.push(p);
            matchingNodeIDs.push(p.nodeID());
          }
        }

        if (newPackages.length > 0) {
          newModules.push(
            new ModuleEvents(m.moduleName, m.pkgPath, newPackages),
          );
          matchingNodeIDs.push(m.nodeID());
        }
      }
      return {
        filtered: new EventsTree(newModules),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    const pkgName =
      inner.type != "function" ? inner.pkgName : inner.function.Package;
    const pkg = module.packages.find((p) => p.packageName == pkgName);
    if (pkg == undefined) {
      throw new Error(`Package ${pkgName} not found`);
    }
    matchingNodeIDs.push(pkg.nodeID());

    if (inner.type == "package") {
      return {
        filtered: new EventsTree([
          new ModuleEvents(module.moduleName, module.pkgPath, [pkg]),
        ]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "type") {
      const type = pkg.types.find((t) => t.typeName == inner.typeName);
      if (type == undefined) {
        throw new Error(
          `Type ${inner.typeName} not found in package ${pkgName}`,
        );
      }
      matchingNodeIDs.push(type.nodeID());

      return {
        filtered: new EventsTree([
          new ModuleEvents(module.moduleName, module.pkgPath, [
            new PackageEvents(pkgName, [type], []),
          ]),
        ]),
        matchingNodesIDs: matchingNodeIDs,
      };
    }

    if (inner.type == "function") {
      const typeName = inner.function.Type;
      if (typeName) {
        const type = pkg.types.find((t) => t.typeName == typeName);
        if (type == undefined) {
          throw new Error(`Type ${typeName} not found in package ${pkgName}`);
        }
        matchingNodeIDs.push(type.nodeID());

        const func = type.events.find(
          (e) => e.func.Name == inner.function.Name,
        );
        if (func == undefined) {
          throw new Error(
            `Function ${inner.function.Name} not found in type ${typeName}`,
          );
        }
        matchingNodeIDs.push(func.nodeID());

        return {
          filtered: new EventsTree([
            new ModuleEvents(module.moduleName, module.pkgPath, [
              new PackageEvents(
                pkgName,
                [new TypeEvents(typeName, pkgName, [func])],
                [],
              ),
            ]),
          ]),
          matchingNodesIDs: matchingNodeIDs,
        };
      } else {
        // The function is not a method.
        const func = pkg.functionEvents.find(
          (e) => e.func.Name == inner.function.Name,
        );
        if (func == undefined) {
          throw new Error(
            `Function ${inner.function.Name} not found in package ${pkgName}`,
          );
        }
        matchingNodeIDs.push(func.nodeID());
        return {
          filtered: new EventsTree([
            new ModuleEvents(module.moduleName, module.pkgPath, [
              new PackageEvents(pkgName, [], [func]),
            ]),
          ]),
          matchingNodesIDs: matchingNodeIDs,
        };
      }
    }
    exhaustiveCheck(inner);
  };

  // Find the event corresponding to a particular function, if any.
  findEvent = (
    funcName: FunctionName,
  ): [EventInfo, ModuleEvents] | undefined => {
    for (const m of this.modules) {
      const e = m.findEvent(funcName);
      if (e) {
        return [e, m];
      }
    }
    return undefined;
  };
}

type filterResults = {
  filtered: EventsTree;
  matchingNodesIDs: string[];
};

// EventsGroups represents a group of event specs (or a single event) that can
// be selected for inclusion in a log.
type EventsGroup = ModuleEvents | PackageEvents | TypeEvents | EventInfo;

export class TreeNode {
  type: "module" | "package" | "type" | "function";
  events: EventsGroup;
  name: string;
  children: TreeNode[];
  parent: TreeNode | undefined;
  // A unique ID for this node, as the TreeItem component wants.
  id: string;

  constructor(
    type: "module" | "package" | "type" | "function",
    events: EventsGroup,
    name: string,
    children: TreeNode[],
    id: string,
  ) {
    this.type = type;
    this.events = events;
    this.name = name;
    this.children = children;
    this.id = id;
  }

  static fromModule = (module: ModuleEvents): TreeNode => {
    return new TreeNode(
      "module",
      module,
      module.moduleName,
      module.packages.map(TreeNode.fromPackage),
      module.nodeID(),
    );
  };

  static fromPackage = (pkg: PackageEvents): TreeNode => {
    return new TreeNode(
      "package",
      pkg,
      pkg.packageName,
      [
        ...pkg.types.map(TreeNode.fromType),
        ...pkg.functionEvents.map(TreeNode.fromEvent),
      ],
      pkg.nodeID(),
    );
  };

  static fromType = (type: TypeEvents): TreeNode => {
    return new TreeNode(
      "type",
      type,
      type.typeName,
      type.events.map(TreeNode.fromEvent),
      type.nodeID(),
    );
  };

  static fromEvent = (func: EventInfo): TreeNode => {
    return new TreeNode("function", func, func.func.Name, [], func.nodeID());
  };

  // addAncestors takes a set of nodeIDs and adds all ancestors of the
  // respective nodes to the set. Returns true if the set was modified (if the
  // set was modified, that implies that this module was also added to the set).
  addAncestors = (nodeIDs: Set<string>): boolean => {
    let anyAdded = false;
    for (const n of this.children) {
      const added = n.addAncestors(nodeIDs);
      anyAdded = anyAdded || added;
    }
    let modified = false;
    if (anyAdded || nodeIDs.has(this.id)) {
      modified = true;
      nodeIDs.add(this.id);
    }
    return modified;
  };

  treeIDs(): string[] {
    const ids: string[] = [this.id];
    for (const c of this.children) {
      ids.push(c.id);
      ids.push(...c.treeIDs());
    }
    return ids;
  }
}

class ModuleEvents {
  type = "module" as const;
  moduleName: string;
  // The prefix of the package path that defines this module.
  pkgPath: string;
  packages: PackageEvents[];

  constructor(moduleName: string, pkgPath: string, packages: PackageEvents[]) {
    this.moduleName = moduleName;
    this.pkgPath = pkgPath;
    this.packages = packages;
  }

  filter = (
    query: string,
  ): {filtered: ModuleEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.moduleName.includes(query)) {
      // TODO(andrei): Also test the query against the children, recursively, to
      // collect more matchingNodeIDs?
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const newPkgs: PackageEvents[] = [];
    const matchingNodeIDs: string[] = [this.nodeID()];
    for (const e of this.packages) {
      const res = e.filter(query);
      if (res) {
        newPkgs.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }

    if (newPkgs.length > 0) {
      return {
        filtered: new ModuleEvents(this.moduleName, this.pkgPath, newPkgs),
        matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): ModuleEventsSelection => {
    return new ModuleEventsSelection(this.pkgPath);
  };

  // Find the event corresponding to a particular function, if any.
  findEvent = (funcName: FunctionName): EventInfo | undefined => {
    const pkg = this.packages.find((p) => p.packageName == funcName.Package);
    if (pkg == undefined) {
      return undefined;
    }
    return pkg.findEvent(
      funcName.Type == "" ? undefined : funcName.Type,
      funcName.Name,
    );
  };
}

class PackageEvents {
  type = "pkg" as const;
  packageName: string = "";
  types: TypeEvents[] = [];
  // Events corresponding to functions in this package (as opposed to methods on
  // types in this package, which are stored in `types`).
  functionEvents: EventInfo[] = [];

  constructor(
    packageName: string,
    types: TypeEvents[],
    functionEvents: EventInfo[],
  ) {
    this.packageName = packageName;
    this.types = types;
    this.functionEvents = functionEvents;
  }

  filter = (
    query: string,
  ): {filtered: PackageEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.packageName.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const matchingNodeIDs: string[] = [this.nodeID()];
    const newFunctionEvents: EventInfo[] = [];
    for (const e of this.functionEvents) {
      const res = e.filter(query);
      if (res) {
        newFunctionEvents.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    const newTypes: TypeEvents[] = [];
    for (const t of this.types) {
      const res = t.filter(query);
      if (res) {
        newTypes.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    if (newTypes.length > 0 || newFunctionEvents.length > 0) {
      return {
        filtered: new PackageEvents(
          this.packageName,
          newTypes,
          newFunctionEvents,
        ),
        matchingNodeIDs: matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): PackageEventsSelection => {
    return new PackageEventsSelection(this.packageName);
  };

  // Find the event corresponding to a particular function.
  findEvent = (
    typeName: string | undefined,
    funcName: string,
  ): EventInfo | undefined => {
    if (typeName) {
      const type = this.types.find((t) => t.typeName == typeName);
      if (type == undefined) {
        return undefined;
      }
      return type.findEvent(funcName);
    }
    return this.functionEvents.find((e) => e.func.Name == funcName);
  };
}

class TypeEvents {
  type = "type" as const;
  typeName: string = "";
  pkgName: string = "";
  events: EventInfo[] = [];

  constructor(typeName: string, pkgName: string, events: EventInfo[]) {
    this.typeName = typeName;
    this.pkgName = pkgName;
    this.events = events;
  }

  filter = (
    query: string,
  ): {filtered: TypeEvents; matchingNodeIDs: string[]} | undefined => {
    if (this.typeName.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }

    const newEvents: EventInfo[] = [];
    const matchingNodeIDs: string[] = [this.nodeID()];
    for (const e of this.events) {
      const res = e.filter(query);
      if (res) {
        newEvents.push(res.filtered);
        matchingNodeIDs.push(...res.matchingNodeIDs);
      }
    }
    if (newEvents.length > 0) {
      return {
        filtered: new TypeEvents(this.typeName, this.pkgName, newEvents),
        matchingNodeIDs: matchingNodeIDs,
      };
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): TypeEventsSelection => {
    return new TypeEventsSelection(this.typeName, this.pkgName);
  };

  // Find the event corresponding to a particular function/method name. Note
  // that funcName is NOT a fully-qualified name.
  findEvent = (funcName: string): EventInfo | undefined => {
    return this.events.find((e) => e.func.Name == funcName);
  };
}

class EventInfo {
  type = "func" as const;
  spec: FunctionStartEventSpec;
  func: FunctionName;

  constructor(spec: FunctionStartEventSpec, func: FunctionName) {
    this.spec = spec;
    this.func = func;
  }

  filter = (
    query: string,
  ): {filtered: EventInfo; matchingNodeIDs: string[]} | undefined => {
    if (this.func.Name.includes(query)) {
      return {filtered: this, matchingNodeIDs: [this.nodeID()]};
    }
    return undefined;
  };

  nodeID = (): string => {
    return this.toSelection().nodeID();
  };

  toSelection = (): EventInfoSelection => {
    return new EventInfoSelection(this.func.QualifiedName);
  };
}
