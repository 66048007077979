import {UNNAMED_ENV} from "src/constants/unnamed_env";

export type ProgramSelectionStatus =
  | AllProcessesSelected
  | NoProcessesSelected
  | SomeProcessesSelected;

export type AllProcessesSelected = {
  type: "all";
};

export type NoProcessesSelected = {
  type: "none";
};

export type SomeProcessesSelected = {
  type: "some";
  selectedProcs: ProcessInfo[];
};

interface ProcessInfoData {
  host: {
    ip: string | undefined;
    hostname: string;
  };
  agentID: string;
  pid: number;
  processToken: string;
  programName: string;
  // a user-assigned identifier for this process
  processID: string;
  binary: BinaryInfo;
  environment: string | typeof UNNAMED_ENV;
  supportsEvents: boolean;
  isLibrary: boolean;
}

export class ProcessInfo implements ProcessInfoData {
  host: {
    ip: string | undefined;
    hostname: string;
  };
  agentID: string;
  pid: number;
  processToken: string;
  programName: string;
  // a user-assigned identifier for this process
  processID: string;
  binary: BinaryInfo;
  environment: string | typeof UNNAMED_ENV;
  supportsEvents: boolean;
  isLibrary: boolean;

  constructor(data: ProcessInfoData) {
    this.host = data.host;
    this.agentID = data.agentID;
    this.pid = data.pid;
    this.processToken = data.processToken;
    this.programName = data.programName;
    this.processID = data.processID;
    this.binary = data.binary;
    this.environment = data.environment;
    this.supportsEvents = data.supportsEvents;
    this.isLibrary = data.isLibrary;
  }

  toJSON(): string {
    return this.processToken;
  }
}

export type BinaryInfo = {
  hash: string;
  unknown: boolean;
  binaryName: string;
};

export type PprofAddress =
  | {
      type: "address";
      // value is the same as url if the value can be parsed as a URL. value can
      // also be a port number, in which case the URL is constructed as
      // http://localhost:port/debug/pprof.
      value: string;
      url: string;
    }
  | {
      // Processes monitored through the side-eye-go library don't have a pprof
      // address per se, but their pprof data can be collected.
      type: "library";
    }
  | {
      type: "invalid";
      value: string;
    };
