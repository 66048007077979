import {HelpCircle} from "@components/HelpCircle";
import {SelectorBinary} from "@components/SelectorBinary";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import type {
  FullSnapshotSpecFragment,
  FunctionLines,
  FunctionName,
  FunctionStartEventSpec,
} from "../../../__generated__/graphql";
import TextField from "@mui/material/TextField";
import {EventsGroupSelection, LineEventType, PageState} from "../types";
import {addOrUpdateFunctionSpec} from "../../../util/queries";
import {funcOrMethodNameWithShortPkg} from "../../../util/util";
import {
  functionAutocompletionOption,
  moduleAutocompletionOption,
  packageAutocompletionOption,
  packagePrefixAutocompleteOption,
  typeAutocompletionOption,
} from "../../../components/tables/util";
import {useApolloClient} from "@apollo/client";
import EventsTreeView, {EventsTree, TreeNode} from "./EventsTreeView";
import {suggestPackagePrefixes} from "@components/filter";
import {SpecContext} from "../../../providers/spec-provider";
import {debouncedListFileLinesFromBinary} from "../../../util/debouncedListFileLinesFromBinary";
import {useBinarySelectionDialog} from "../../../providers/binary-selection-dialog";
import {debouncedListFunctionsFromBinary} from "../../../util/debouncedListFunctionsFromBinary";
import {
  autocompleteOption,
  autocompleteOptionExistingEventWrapper,
  autocompleteOptionNewFunction,
  autocompleteOptionSelectBinary,
} from "./types";
import LineProbeItem from "./LineProbeItem";
import FileAutocomplete from "./FileAutocomplete";
import AddIcon from "@mui/icons-material/Add";

export type EventsCardProps = {
  state: PageState;
  binariesForSelectedProcs: string[];
  selectionRequired: boolean;
};

export default function EventsCard(props: EventsCardProps): React.JSX.Element {
  const client = useApolloClient();
  const {state, binariesForSelectedProcs} = props;
  const spec = useContext(SpecContext);
  const tree: EventsTree = useMemo(() => specToTree(spec), [spec]);
  const [filterInputValue, setFilterInputValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState<
    autocompleteOption | string | null
  >(null);
  const [expandedNodeIDs, setExpandedNodeIDs] = useState<string[]>([]);
  const [matchingFunctions, setMatchingFunctions] = useState<FunctionName[]>(
    [],
  );
  const [selectedBinaryID, setSelectedBinaryID] = useState<string | undefined>(
    undefined,
  );
  const binaryID =
    selectedBinaryID ??
    // If there is a single binary matching the current selection, use it.
    (binariesForSelectedProcs.length == 1
      ? binariesForSelectedProcs[0]
      : undefined);

  // Filter the events according to the filter values. Note that expandedNodeIDs
  // has already been adjusted on filter changes.
  let filteredTree: EventsTree;
  if (filterValue == null) {
    filteredTree = tree;
  } else {
    if (typeof filterValue == "string" || isExistingEvent(filterValue)) {
      const filterRes = tree.filter(filterValue);
      filteredTree = filterRes.filtered;
    } else {
      filteredTree = tree;
    }
  }

  const [newLineProbeSelectedFilePath, setNewLineProbeSelectedFilePath] =
    useState(null as string | null);
  const [newLineProbeFilePathInput, setNewLineProbeFilePathInput] =
    useState("");
  type FileLineOption = {
    line: number;
    function: FunctionName;
  };
  const [newLineProbeLineNumberValue, setNewLineProbeLineNumberValue] =
    useState<FileLineOption | null>(null);
  const [newLineProbeLineNumberOptions, setNewLineProbeLineNumberOptions] =
    useState<FileLineOption[] | null>(null);

  const newLineEventSelectFilePath = (path: string | null) => {
    setNewLineProbeSelectedFilePath(path);
    if (path) {
      debouncedListFileLinesFromBinary(
        client,
        binaryID!,
        path,
        (results: FunctionLines[]) => {
          setNewLineProbeLineNumberOptions(
            results
              .flatMap((f: FunctionLines) => {
                return f.Lines.map((line) => {
                  return {
                    line,
                    function: f.Function,
                  } as FileLineOption;
                });
              })
              .sort((a, b) => a.line - b.line),
          );
        },
      );
    } else {
      setNewLineProbeLineNumberOptions(null);
    }
  };

  const showBinarySelectionDialog = useBinarySelectionDialog();

  const existingSpecOptions: autocompleteOption[] = useMemo(
    () => specToAutocompleteSuggestions(tree),
    [tree],
  );

  async function promptForBinarySelection() {
    const binaryID = await showBinarySelectionDialog(
      undefined /* snapshotID */,
      undefined /* funcQualifiedName */,
    );
    if (binaryID == undefined) {
      return;
    }
    setSelectedBinaryID(binaryID);
  }

  const options: autocompleteOption[] = [...existingSpecOptions];
  if (binaryID) {
    options.push(
      ...matchingFunctions
        // Remove all the functions that are already in the tree.
        .filter((f: FunctionName) => !tree.findEvent(f))
        .map((fn: FunctionName) => new autocompleteOptionNewFunction(fn)),
    );
  } else {
    options.push(new autocompleteOptionSelectBinary(promptForBinarySelection));
  }

  // When the input changes, update the matching functions from the binary. We
  // do this asynchronous operation in an effect, rather than in the onChange
  // handler, because we want to be able to cancel it if the input changes
  // again.
  useEffect(() => {
    if (binaryID == undefined) {
      return;
    }
    if (filterInputValue == "") {
      setMatchingFunctions([]);
      return;
    }
    let active = true;
    debouncedListFunctionsFromBinary(
      client,
      binaryID,
      filterInputValue,
      (results: FunctionName[]) => {
        if (!active) {
          return; // The operation was cancelled.
        }
        setMatchingFunctions(results);
      },
    );
    return () => {
      active = false;
    };
  }, [filterInputValue, binaryID, client]);

  async function addFunctionToSpec(fn: FunctionName) {
    const newSpec = await addOrUpdateFunctionSpec(
      client,
      {
        funcQualifiedName: fn.QualifiedName,
        functionStartEvent: {
          // Default the message to the function name.
          message: funcOrMethodNameWithShortPkg(fn),
        },
      },
      undefined /* showConfirmationDialog - no validation needed for empty specs */,
    );
    if (!newSpec) {
      return;
    }

    // Find the event corresponding to the new function and filter the tree to it.
    const tree = specToTree(newSpec);
    const foundEvent = tree.findEvent(fn);
    if (foundEvent == undefined) {
      throw new Error(`cannot find event for ${fn.QualifiedName}`);
    }
    const [_newEvent, module] = foundEvent;
    const autocompleteOpt = new autocompleteOptionExistingEventWrapper(
      new functionAutocompletionOption(fn),
      module.moduleName,
    );
    setFilterValue(autocompleteOpt);
    const filterRes = tree.filter(autocompleteOpt);

    // Also expand the ancestors the newly-selected node.
    const nodes: TreeNode[] = tree.modules.map(TreeNode.fromModule);
    const expandedNodeIDs = new Set<string>(filterRes.matchingNodesIDs);
    for (const n of nodes) {
      n.addAncestors(expandedNodeIDs);
    }
    setExpandedNodeIDs(Array.from(expandedNodeIDs));
    return;
  }

  function addLineProbe(file: string, func: FunctionName, line: number) {
    if (
      state.lineEvents.find(
        (ev) =>
          ev.file == file &&
          ev.function.QualifiedName == func.QualifiedName &&
          ev.line == line,
      ) != undefined
    ) {
      // Event already exists; nothing to do.
      return;
    }
    const lineEvents = [...state.lineEvents];
    lineEvents.push({file, function: func, line, eventSpec: {exprs: []}});
    // The new event start off as selected.
    const selectedLineProbes = [...state.selectedLineProbes];
    selectedLineProbes.push([func.QualifiedName, line]);
    state.updateSearchParams({
      lineEvents,
      selectedLineProbes,
    });
  }

  function toggleLineEvent(
    checked: boolean,
    funcName: FunctionName,
    line: number,
  ) {
    let newLineEvents = state.selectedLineProbes;
    if (checked) {
      if (
        newLineEvents.find(
          ([evFuncQualifiedName, evLine]) =>
            evFuncQualifiedName == funcName.QualifiedName && evLine == line,
        ) != undefined
      ) {
        throw new Error("line probe already selected");
      }

      newLineEvents.push([funcName.QualifiedName, line]);
    } else {
      if (
        newLineEvents.find(
          ([evFuncName, evLine]) =>
            evFuncName == funcName.QualifiedName && evLine == line,
        ) == undefined
      ) {
        throw new Error("line probe not selected");
      }

      newLineEvents = newLineEvents.filter(
        ([evFuncName, evLine]) =>
          evFuncName != funcName.QualifiedName || evLine != line,
      );
    }
    state.updateSearchParams({selectedLineProbes: Array.from(newLineEvents)});
  }

  return (
    <Card>
      <CardHeader
        title="Select probes to enable"
        subheader="Events will be generated by the selected probes."
      />
      <CardContent>
        <Stack direction="row" alignItems="center" gap={1}>
          <Autocomplete
            fullWidth
            options={options}
            // The user can also type any string to filter the events in the
            // tree.
            freeSolo={true}
            // The selectBinary option is treated as disabled; we don't want
            // the user to be able to select it; we only want them to
            // interact with it through the button that it renders.
            getOptionDisabled={(opt) => opt.type == "selectBinary"}
            groupBy={(option: autocompleteOption) => {
              if (option instanceof autocompleteOptionExistingEventWrapper) {
                return "Existing probes";
              }
              return "Other matching functions";
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Filter probes" />
            )}
            // We take control over the filtering process so that we can do our
            // own matching of the query to the suggestions. Also, the suggestions
            // change in response to the query -- parts of the string get
            // highlighted. Also, some suggestions (the package prefixes) are
            // generated based on the query.
            filterOptions={(options, state) =>
              matchAutocompleteOptions(options, state.inputValue)
            }
            renderOption={(props, opt: autocompleteOption) => {
              if (isExistingEvent(opt)) {
                return opt.opt.render(props);
              }
              return opt.render(props);
            }}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(_, value: string | autocompleteOption | null) => {
              if (value == null) {
                setExpandedNodeIDs([]);
                setFilterValue(value);
                return;
              }
              // If the user selected an existing event, or simply typed a
              // string to use for filtering, find the matching nodes and
              // expand their ancestors.
              if (typeof value == "string" || isExistingEvent(value)) {
                const filterRes = tree.filter(value);

                // Also expand the ancestors of any node in expandedNodeIDs.
                const nodes: TreeNode[] = tree.modules.map(TreeNode.fromModule);
                const expandedNodeIDs = new Set<string>(
                  filterRes.matchingNodesIDs,
                );
                for (const n of nodes) {
                  n.addAncestors(expandedNodeIDs);
                }
                setExpandedNodeIDs(Array.from(expandedNodeIDs));
                setFilterValue(value);
                return;
              }

              if (value.type == "selectBinary") {
                throw new Error(
                  "bug: the select binary options was not supposed to be selectable",
                );
              }

              // A new function was selected. Add it to the spec.
              void addFunctionToSpec(value.funcName);
              setFilterValue(value);
            }}
            value={filterValue}
            inputValue={filterInputValue}
            onInputChange={(_event, value) => setFilterInputValue(value)}
          />
          <HelpCircle
            tip={`Filter the events by function name, type, package or module.`}
          />
        </Stack>
        <Box sx={{mt: 2}}>
          <EventsTreeView
            tree={filteredTree}
            noEventsMessage={
              tree.modules.length == 0
                ? "No events defined."
                : "No events match."
            }
            expandedNodeIDs={expandedNodeIDs}
            setExpandedNodeIDs={setExpandedNodeIDs}
            checkedNodes={state.selectedEvents}
            setCheckedNodes={(eventGroupSelections: EventsGroupSelection[]) => {
              state.updateSearchParams({
                selectedEvents: eventGroupSelections,
              });
            }}
            binaryID={binaryID ?? (() => void promptForBinarySelection())}
          />
        </Box>
        {props.selectionRequired && (
          <Typography variant={"error"}>Selection required</Typography>
        )}
        <Box sx={{mt: 2}}>
          <Typography>Line probes</Typography>
          <List>
            {state.lineEvents?.map((ev, index) => (
              <ListItem key={index}>
                <LineProbeItem
                  file={ev.file}
                  funcName={ev.function}
                  lineNumber={ev.line}
                  eventSpec={lineEventToEventSpec(ev)}
                  selected={
                    (state.selectedLineProbes ?? []).find(
                      ([selFuncName, selLine]) =>
                        selFuncName == ev.function.QualifiedName &&
                        selLine == ev.line,
                    ) != undefined
                  }
                  onSelectedChange={(funcName, line, checked) =>
                    toggleLineEvent(checked, funcName, line)
                  }
                  onExprAdded={(
                    func: FunctionName,
                    line: number,
                    expr: string,
                  ) => {
                    const newLineEvents = state.lineEvents.map((ev) =>
                      ev.function.QualifiedName == func.QualifiedName &&
                      ev.line == line
                        ? addExprToEvent(ev, expr)
                        : ev,
                    );
                    state.updateSearchParams({lineEvents: newLineEvents});
                  }}
                  onExprRemoved={(
                    func: FunctionName,
                    line: number,
                    expr: string,
                  ) => {
                    const newLineEvents = state.lineEvents.map((ev) =>
                      ev.function.QualifiedName == func.QualifiedName &&
                      ev.line == line
                        ? removeExprFromEvent(ev, expr)
                        : ev,
                    );
                    state.updateSearchParams({lineEvents: newLineEvents});
                  }}
                  binaryID={binaryID}
                />
              </ListItem>
            ))}
          </List>

          {binaryID != undefined ? (
            <Stack direction="row" gap={2} alignItems={"center"}>
              <Typography noWrap={true}>Add new line probe</Typography>
              <FileAutocomplete
                sx={{flexGrow: 1}}
                binaryID={binaryID}
                inputValue={newLineProbeFilePathInput}
                value={newLineProbeSelectedFilePath}
                onValueChange={(val) => newLineEventSelectFilePath(val)}
                onInputChange={(val) => setNewLineProbeFilePathInput(val)}
              />
              <Autocomplete
                sx={{flexGrow: 1}}
                disablePortal
                disabled={newLineProbeLineNumberOptions == null}
                options={newLineProbeLineNumberOptions ?? []}
                filterOptions={(o, s) => {
                  if (s.inputValue == "" || o.length == 0) {
                    return o;
                  }
                  const inputLine = Number.parseInt(s.inputValue);
                  const idx = o.findLastIndex((fl) => fl.line <= inputLine);
                  if (idx == -1) {
                    return [o[0]];
                  }
                  if (o[idx].line == inputLine || idx == o.length - 1) {
                    return [o[idx]];
                  }
                  return [o[idx], o[idx + 1]];
                }}
                groupBy={(option) => functionFriendlyName(option.function)}
                getOptionLabel={(option) => option.line.toString()}
                value={newLineProbeLineNumberValue}
                onChange={(_e, newValue) => {
                  setNewLineProbeLineNumberValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      newLineProbeLineNumberValue
                        ? functionFriendlyName(
                            newLineProbeLineNumberValue.function,
                          )
                        : "line number"
                    }
                  />
                )}
              />
              <Tooltip title={"Add new file:line probe"}>
                <span>
                  <Button
                    disabled={
                      newLineProbeSelectedFilePath == null ||
                      newLineProbeLineNumberValue == null
                    }
                    onClick={() => {
                      addLineProbe(
                        newLineProbeSelectedFilePath!,
                        newLineProbeLineNumberValue!.function,
                        newLineProbeLineNumberValue!.line,
                      );
                    }}
                    color="info"
                    variant="outlined"
                  >
                    <AddIcon />
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          ) : (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography>Select binary to add line probes</Typography>
              <SelectorBinary setBinaryID={setSelectedBinaryID} />
            </Stack>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export function lineEventToEventSpec(
  ev: LineEventType,
): FunctionStartEventSpec {
  return {
    collectExprs: ev.eventSpec.exprs.map((expr) => ({
      expr: expr,
      column: {name: expr, type: null, hidden: false},
      opt: null,
    })),
    message: `${ev.function.QualifiedName}:${ev.line}`,
    extraColumns: [],
    tableName: `${ev.function.QualifiedName}_${ev.line}`,
  };
}

function addExprToEvent(ev: LineEventType, expr: string): LineEventType {
  // If the expr already exists, there's nothing to do.
  if (ev.eventSpec.exprs.includes(expr)) {
    return ev;
  }

  return {
    ...ev,
    eventSpec: {...ev.eventSpec, exprs: [...ev.eventSpec.exprs, expr]},
  };
}

function removeExprFromEvent(ev: LineEventType, expr: string): LineEventType {
  // If the expr doesn't exist, there's nothing to do.
  if (!ev.eventSpec.exprs.includes(expr)) {
    return ev;
  }

  return {
    ...ev,
    eventSpec: {
      ...ev.eventSpec,
      exprs: ev.eventSpec.exprs.filter((e) => e != expr),
    },
  };
}

function isOptionEqualToValue(
  opt: autocompleteOption,
  value: autocompleteOption | string,
): boolean {
  if (typeof value == "string") {
    return false;
  }

  if (isExistingEvent(opt) != isExistingEvent(value)) {
    return false;
  }

  if (isExistingEvent(opt)) {
    if (!isExistingEvent(value)) {
      return false;
    }
    return opt.opt.equals(value.opt);
  }

  if (isExistingEvent(value)) {
    return false;
  }

  if (opt.type == "selectBinary" || value.type == "selectBinary") {
    // The selectBinary option can never be selected.
    return false;
  }

  return opt.funcName.QualifiedName == value.funcName.QualifiedName;
}

function specToTree(spec: FullSnapshotSpecFragment): EventsTree {
  const eventsTree = new EventsTree();
  const mm = spec.modules;
  for (let i = 0; i < mm.length; i++) {
    const module = spec.modules[i];
    for (const func of module.functionSpecs) {
      if (func.functionStartEvent) {
        eventsTree.addFunctionStartEvent(
          func.functionStartEvent,
          module.name,
          module.pkgPath,
          func,
        );
      }
    }
  }
  return eventsTree;
}

// matchAutocompleteOptions takes a list of options and a query string and
// returns the options that match the query. The match field of the returned
// options is set.
function matchAutocompleteOptions(
  options: autocompleteOption[],
  query: string,
): autocompleteOption[] {
  const matches: autocompleteOption[] = [];
  const caseSensitive = query.toLowerCase() != query;

  // Keep track of the package prefix suggestions generated, as multiple
  // packages can generate the same prefix.
  const packagePrefixes = new Set<string>();
  // Usually we don't start with any package prefix suggestions in the input.
  // However, if a package prefix is selected, that one is also part of
  // `options`. So, we initialize packagePrefixes with any such option, so we
  // don't generate it again.
  for (const o of options) {
    if (isExistingEvent(o) && o.opt.type == "packagePrefix") {
      packagePrefixes.add(o.opt.pkg);
    }
  }

  for (const o of options) {
    // The functions from binary have already been filtered to the ones matching
    // the query.
    if (!isExistingEvent(o)) {
      matches.push(o);
      continue;
    }

    if (o.opt.match(query, caseSensitive)) {
      matches.push(o);
    }

    if (o.opt.type == "package") {
      // Generate package prefix suggestions.
      const prefixSuggestion = suggestPackagePrefixes(o.opt.pkgName, query);
      for (const [pkgPrefix, _highlighted] of prefixSuggestion) {
        if (packagePrefixes.has(pkgPrefix)) {
          continue;
        }
        const p = new packagePrefixAutocompleteOption(pkgPrefix);
        if (p.match(query, caseSensitive)) {
          matches.push(
            new autocompleteOptionExistingEventWrapper(p, o.moduleName),
          );
          packagePrefixes.add(pkgPrefix);
        } else {
          throw new Error(
            `suggestion was expected to match: ${pkgPrefix} query ${query}`,
          );
        }
      }
    }
  }

  return matches;
}

function getOptionKey(option: autocompleteOption | string): string {
  if (typeof option === "string") {
    return option;
  }
  if (isExistingEvent(option)) {
    return option.opt.key();
  }
  if (option.type == "selectBinary") {
    return "select binary";
  }
  return option.funcName.QualifiedName;
}

function getOptionLabel(option: autocompleteOption | string): string {
  if (typeof option === "string") {
    return option;
  }
  if (isExistingEvent(option)) {
    return option.opt.label();
  }
  if (option.type == "selectBinary") {
    // We never render this options, so it should never be selected.
    throw new Error("select binary selected");
  }
  return option.funcName.QualifiedName;
}

function functionFriendlyName(fn: FunctionName): string {
  if (fn.Type) {
    return `${fn.Type}.${fn.Name}`;
  }
  return `${fn.Package}.${fn.Name}`;
}

function isExistingEvent(
  opt: autocompleteOption,
): opt is autocompleteOptionExistingEventWrapper {
  return (
    opt.type == "function" ||
    opt.type == "package" ||
    opt.type == "packagePrefix" ||
    opt.type == "type" ||
    opt.type == "module"
  );
}

function specToAutocompleteSuggestions(
  tree: EventsTree,
): autocompleteOptionExistingEventWrapper[] {
  const suggestions: autocompleteOptionExistingEventWrapper[] = [];
  for (const module of tree.modules) {
    suggestions.push(
      new autocompleteOptionExistingEventWrapper(
        new moduleAutocompletionOption(module.moduleName),
        module.moduleName,
      ),
    );
    for (const pkg of module.packages) {
      suggestions.push(
        new autocompleteOptionExistingEventWrapper(
          new packageAutocompletionOption(pkg.packageName),
          module.moduleName,
        ),
      );
      for (const type of pkg.types) {
        suggestions.push(
          new autocompleteOptionExistingEventWrapper(
            new typeAutocompletionOption(pkg.packageName, type.typeName),
            module.moduleName,
          ),
        );
        for (const func of type.events) {
          suggestions.push(
            new autocompleteOptionExistingEventWrapper(
              new functionAutocompletionOption(func.func),
              module.moduleName,
            ),
          );
        }
      }
      for (const func of pkg.functionEvents) {
        suggestions.push(
          new autocompleteOptionExistingEventWrapper(
            new functionAutocompletionOption(func.func),
            module.moduleName,
          ),
        );
      }
    }
  }
  return suggestions;
}
