import {
  BooleanParamUpdater,
  computeSearchParams,
  MapParamUpdater,
  NumberParamUpdater,
  ParamUpdater,
  stateFromURL,
  StringsParamUpdater,
  UpdateSpec,
  ZodParamUpdater,
} from "../../util/url";
import {
  ProcessSelection,
  ProcessSelectionJSONSchema,
  RawProcessSelection,
} from "../../components/ProcessSelector/helpers/processSelection";
import type {
  FullSnapshotSpecFragment,
  FunctionName,
  FunctionSpec,
  FunctionStartEventSpec,
} from "../../__generated__/graphql";
import {exhaustiveCheck} from "../../util/util";
import {z, type ZodType} from "zod";
import {SetURLSearchParams} from "react-router-dom";
import {EnvironmentProcesses} from "../../components/ProcessSelector/helpers/processHierarchy";

const funcNameSchema = z.object({
  Name: z.string(),
  Package: z.string(),
  QualifiedName: z.string(),
  Type: z.string(),
}) satisfies ZodType<FunctionName>;

// TODO: This should mimic FunctionStartEventSpec (i.e. have information about
// column names, etc.).
const eventSchema = z.object({
  // The list of expressions to capture.
  exprs: z.array(z.string()),
});

const lineProbeSchema = z.object({
  file: z.string(),
  line: z.number(),
  // The function containing the respective line of code.
  function: funcNameSchema,
  eventSpec: eventSchema,
});

const lineEventsSchema = z.array(lineProbeSchema);
export type LineEventType = z.infer<typeof lineProbeSchema>;

const selectedProbesSchema = z.array(z.tuple([z.string(), z.number()]));
type SelectedProbesType = z.infer<typeof selectedProbesSchema>;

type paramUpdaters = {
  processSelection: ZodParamUpdater<typeof ProcessSelectionJSONSchema>;
  logDuration: NumberParamUpdater;
  selectedEvents: ParamUpdater<EventsGroupSelection[], EventsGroupSelection[]>;
  captureSnapshot: BooleanParamUpdater;
  captureExecutionTrace: BooleanParamUpdater;
  // The overrides for the pprof addresses. These apply on top of the default
  // addresses coming from the pprof rules. Map from process token to address
  // (or port number).
  pprofAddresses: MapParamUpdater;
  lineEvents: ZodParamUpdater<typeof lineEventsSchema>;
  selectedLineProbes: ZodParamUpdater<typeof selectedProbesSchema>;
};

function makeParamUpdaters(): paramUpdaters {
  const sp = new StringsParamUpdater("events");
  return {
    processSelection: new ZodParamUpdater(
      "processes",
      ProcessSelectionJSONSchema,
    ),
    logDuration: new NumberParamUpdater("logDuration"),
    selectedEvents: new ParamUpdater<
      EventsGroupSelection[],
      EventsGroupSelection[]
    >(
      "events",
      (
        _param: string,
        searchParams: URLSearchParams,
      ): EventsGroupSelection[] => {
        const events: string[] = sp.get(searchParams);
        return events.map((s) => {
          if (s.startsWith("mod:")) {
            return new ModuleEventsSelection(s.slice(4));
          }
          if (s.startsWith("pkg:") && s.includes(":type:")) {
            const parts = s.split(":");
            return new TypeEventsSelection(parts[3], parts[1]);
          }
          if (s.startsWith("pkg:")) {
            return new PackageEventsSelection(s.slice(4));
          }
          if (s.startsWith("func:")) {
            return new EventInfoSelection(s.slice(5));
          }
          throw new Error(`unexpected events selection: ${s}`);
        });
      },
      (
        _param: string,
        searchParams: URLSearchParams,
        newValue: EventsGroupSelection[],
      ) => {
        return sp.update(
          searchParams,
          newValue.map((e: EventsGroupSelection): string => e.nodeID()),
        );
      },
    ),
    captureSnapshot: new BooleanParamUpdater("captureSnapshot", true),
    captureExecutionTrace: new BooleanParamUpdater("captureExecutionTrace"),
    pprofAddresses: new MapParamUpdater("pprofAddresses"),
    lineEvents: new ZodParamUpdater("lineEvents", lineEventsSchema),
    selectedLineProbes: new ZodParamUpdater(
      "selectedLineProbes",
      selectedProbesSchema,
    ),
  };
}

// PageState represents the URL state of the page. It wraps paramUpdaters.
export class PageState {
  readonly searchParams: URLSearchParams;
  readonly setSearchParams: SetURLSearchParams;
  readonly paramUpdaters: paramUpdaters;

  readonly processSelection: RawProcessSelection | undefined;
  readonly captureExecutionTrace: boolean;
  readonly captureSnapshot: boolean;
  readonly pprofAddressOverrides: Map<string, string>;
  readonly selectedEvents: EventsGroupSelection[];
  readonly logDuration: number | undefined;
  readonly lineEvents: LineEventType[];
  readonly selectedLineProbes: SelectedProbesType;

  constructor(
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams,
  ) {
    this.searchParams = searchParams;
    this.setSearchParams = setSearchParams;
    this.paramUpdaters = makeParamUpdaters();

    const {
      processSelection: processSelectionFromURL,
      selectedEvents,
      logDuration,
      captureSnapshot: captureSnapshotFromURL,
      captureExecutionTrace: captureExecutionTraceFromURL,
      pprofAddresses: pprofAddressesFromURL,
      lineEvents: urlLineProbes,
      selectedLineProbes: urlSelectedLineProbes,
    } = stateFromURL(searchParams, this.paramUpdaters);
    this.processSelection = processSelectionFromURL;
    this.selectedEvents = selectedEvents;
    this.logDuration = logDuration;
    this.captureExecutionTrace = captureExecutionTraceFromURL ?? false;
    this.captureSnapshot = captureSnapshotFromURL ?? false;
    this.pprofAddressOverrides =
      pprofAddressesFromURL ?? new Map<string, string>();
    this.lineEvents = urlLineProbes || [];
    this.selectedLineProbes = urlSelectedLineProbes || [];
  }

  updateSearchParams(update: UpdateSpec<paramUpdaters>) {
    this.setSearchParams(
      computeSearchParams(this.searchParams, update, this.paramUpdaters),
    );
  }

  // setProcessSelection overwrites the process selection (or clears it if
  // processSelection is undefined). The pprof address overrides are kept in
  // sync with the process selection: overrides for processes that are not part
  // of the selection are removed.
  setProcessSelection(processSelection: ProcessSelection | undefined) {
    const {pprofAddresses} = stateFromURL(
      this.searchParams,
      this.paramUpdaters,
    );
    const pprofAddressOverrides = pprofAddresses ?? new Map<string, string>();
    const rawProcessSelection = processSelection?.toRaw();
    if (processSelection == undefined) {
      // If there's no process selection, clear the pprof address overrides.
      this.updateSearchParams({
        processSelection: rawProcessSelection,
        pprofAddresses: undefined,
      });
    } else {
      // Clear the pprof address overrides for processes that are not in the
      // selected environment.
      const envProcs: EnvironmentProcesses =
        processSelection.allProcsInSelectedEnv();
      for (const tok of pprofAddressOverrides.keys()) {
        if (!envProcs.hasProcess(undefined /* program */, tok)) {
          pprofAddressOverrides.delete(tok);
        }
      }
      this.updateSearchParams({
        processSelection: rawProcessSelection,
        pprofAddresses: pprofAddressOverrides,
      });
    }
  }
}

export type EventsGroupSelection =
  | ModuleEventsSelection
  | PackageEventsSelection
  | TypeEventsSelection
  | EventInfoSelection;

export class ModuleEventsSelection {
  type = "module" as const;
  // The prefix of the package path that defines this module.
  pkgPath: string;

  constructor(pkgPath: string) {
    this.pkgPath = pkgPath;
  }

  nodeID = (): string => {
    return `mod:${this.pkgPath}`;
  };
}

export class PackageEventsSelection {
  type = "pkg" as const;
  packageName: string;

  constructor(packageName: string) {
    this.packageName = packageName;
  }

  nodeID = (): string => {
    return `pkg:${this.packageName}`;
  };
}

export class TypeEventsSelection {
  type = "type" as const;
  typeName: string;
  pkgName: string;

  constructor(typeName: string, pkgName: string) {
    this.typeName = typeName;
    this.pkgName = pkgName;
  }

  nodeID = (): string => {
    return `pkg:${this.pkgName}:type:${this.typeName}`;
  };
}

export class EventInfoSelection {
  type = "func" as const;
  funcQualifiedName: string;

  constructor(funcQualifiedName: string) {
    this.funcQualifiedName = funcQualifiedName;
  }

  nodeID = (): string => {
    return `func:${this.funcQualifiedName}`;
  };
}
