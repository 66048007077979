import React from "react";
import FormControl from "@mui/material/FormControl";
import {PprofAddress} from "../@types";
import TextField from "@mui/material/TextField";

// PprofAddressInput renders a textbox for a pprof address. It takes both the
// current value (address) and the default value (defaultAddress). If the value
// is provided, it overrides the default value. updatePprofAddress() is called
// only when the focus is lost. While the textbox has focus, it renders its own
// dirty state, which can diverge from value/defaultValue. This is so that the
// user can clear the textbox without immediately generating an update event,
// which would then reset the textbox to the defaultValue; we want this reset to
// happen only if the user moves the focus away when the textbox is empty.
export function PprofAddressInput(props: {
  defaultAddress: PprofAddress | undefined;
  address: PprofAddress | undefined;
  updatePprofAddress: (value: string | undefined) => void;
}): React.JSX.Element {
  if (props.defaultAddress?.type == "library") {
    throw new Error("PprofAddressInput cannot be used with library addresses");
  }
  if (props.address?.type == "library") {
    throw new Error("PprofAddressInput cannot be used with library addresses");
  }

  const [focused, setFocused] = React.useState<boolean>(false);
  const [dirtyValue, setDirtyValue] = React.useState<string>(
    props.address?.value ?? props.defaultAddress?.value ?? "",
  );

  // If we're not focused, we display the value from props. If we are focused,
  // we display dirtyValue; once the focus is lost, dirtyValue is propagated up
  // through updatePProfAddress().
  const propsValue = props.address?.value ?? props.defaultAddress?.value ?? "";
  if (!focused) {
    if (dirtyValue != propsValue) {
      setDirtyValue(propsValue);
    }
  }

  return (
    <FormControl>
      <TextField
        variant="outlined"
        placeholder={
          props.defaultAddress?.value ?? "Process pprof HTTP address or port"
        }
        size="small"
        margin="dense"
        value={dirtyValue}
        error={props.address && props.address.type == "invalid"}
        onChange={(e) => setDirtyValue(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
          if (dirtyValue != propsValue) {
            props.updatePprofAddress(dirtyValue == "" ? undefined : dirtyValue);
          }
        }}
      />
    </FormControl>
  );
}
