import {useApolloClient} from "@apollo/client";
import {
  ProcessPprofAddresses,
  ProcessSelector,
} from "@components/ProcessSelector";
import {
  ProcessSelection,
  processSelectionToGraph,
  RawProcessSelection,
  useProcessSelection,
} from "@components/ProcessSelector/helpers/processSelection.ts";
import type {LineSpec} from "@graphql/graphql.ts";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import type React from "react";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useNavigate, useSearchParams} from "react-router-dom";
import {gql} from "src/__generated__";
import {toastError} from "src/components/tables/util.tsx";
import {UNNAMED_ENV} from "src/constants/unnamed_env";
import {match} from "ts-pattern";
import {ProcessHierarchy} from "@components/ProcessSelector/helpers/processHierarchy.ts";
import EventsCard, {lineEventToEventSpec} from "./components/EventsCard";
import {LineEventType, PageState} from "./types";
import {librarySymbol} from "@components/ProcessSelector/helpers/pprofAddresses.ts";

const CAPTURE_RECORDING = gql(/* GraphQL */ `
  mutation captureRecording($input: CaptureRecordingInput!) {
    captureRecording(input: $input) {
      recordingID
      eventLogID
      snapshotID
      cpuProfileID
    }
  }
`);

class RecordingType {
  readonly snapshots: boolean;
  readonly eventLogs: boolean;
  private readonly executionTraces: boolean;
  // set if any of the selected processes have a valid pprof address. If no
  // processes have pprof addresses, then execution traces cannot be captured,
  // regardless of whether the user wants them (through executionTraces).
  readonly pprofValid: boolean;

  constructor(
    snapshots: boolean,
    eventLogs: boolean,
    executionTraces: boolean,
    pprofValid: boolean,
  ) {
    this.snapshots = snapshots;
    this.eventLogs = eventLogs;
    this.executionTraces = executionTraces;
    this.pprofValid = pprofValid;
  }

  something(): boolean {
    return (
      this.snapshots ||
      this.eventLogs ||
      this.shouldCollectExecutionTracesAndCpuProfiles()
    );
  }

  shouldCollectExecutionTracesAndCpuProfiles(): boolean {
    return this.executionTraces && this.pprofValid;
  }

  durationRecording(): boolean {
    return this.shouldCollectExecutionTracesAndCpuProfiles() || this.eventLogs;
  }

  recordingButtonLabel(): string {
    return match({
      snapshots: this.snapshots,
      eventLogs: this.eventLogs,
      executionTraces: this.shouldCollectExecutionTracesAndCpuProfiles(),
    })
      .with(
        {snapshots: true, eventLogs: true, executionTraces: true},
        () => "Capture snapshot, events, and execution trace recording",
      )
      .with(
        {snapshots: true, eventLogs: true, executionTraces: false},
        () => "Capture snapshot and events recording",
      )
      .with(
        {snapshots: true, eventLogs: false, executionTraces: true},
        () => "Capture snapshot and execution trace recording",
      )
      .with(
        {snapshots: false, eventLogs: true, executionTraces: true},
        () => "Capture events and execution trace recording",
      )
      .with(
        {snapshots: true, eventLogs: false, executionTraces: false},
        () => "Capture snapshot recording",
      )
      .with(
        {snapshots: false, eventLogs: true, executionTraces: false},
        () => "Capture events recording",
      )
      .with(
        {snapshots: false, eventLogs: false, executionTraces: true},
        () => "Capture execution trace recording",
      )
      .with(
        {snapshots: false, eventLogs: false, executionTraces: false},
        () => "Capture recording",
      )
      .exhaustive();
  }
}

export default function CaptureRecording(): React.JSX.Element {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const state = new PageState(searchParams, setSearchParams);

  const [processSelection, agentReport, binariesForSelection] =
    useProcessSelection(state.processSelection);

  const [durationError, setDurationError] = useState<boolean>(false);
  const [traceInProgress, setTraceInProgress] = useState<boolean>(false);

  const pprofAddresses = new Map<string, string | typeof librarySymbol>();
  for (const r of agentReport?.Report.Reports ?? []) {
    for (const p of r.Processes) {
      if (r.IsLibrary) {
        pprofAddresses.set(p.ProcessToken, librarySymbol);
      } else if (p.PProfAddress != null) {
        pprofAddresses.set(p.ProcessToken, p.PProfAddress);
      }
    }
  }
  const pprofInformation = new ProcessPprofAddresses(
    pprofAddresses,
    state.pprofAddressOverrides,
    // updateAddress - save the new address in the set of overrides in the URL.
    // Or, if address is undefined, delete it from the list of overrides.
    (processToken: string, address: string | undefined) => {
      const newOverrides = new Map(state.pprofAddressOverrides);
      if (address != undefined) {
        if (address == "") {
          // Empty addresses should have been turned into undefined by callers.
          throw new Error("address cannot be empty");
        }
        newOverrides.set(processToken, address);
      } else {
        newOverrides.delete(processToken);
      }
      state.updateSearchParams({
        pprofAddresses: newOverrides,
      });
    },
  );

  // If execution traces and CPU profiles are enabled, check if any processes
  // have a pprof address configured. If not, we'll display a warning.
  const pprofValid: boolean =
    processSelection != undefined &&
    processSelection
      .selectedProcessTokens()
      .keys()
      .some((tok) => {
        const [baseAddress, overrideAddress] =
          pprofInformation.getProcessAddress(tok);
        if (overrideAddress != undefined) {
          return overrideAddress.type != "invalid";
        }
        return baseAddress != undefined;
      });

  const recordingType = new RecordingType(
    state.captureSnapshot,
    state.selectedEvents.length > 0 || state.selectedLineProbes.length > 0,
    state.captureExecutionTrace,
    pprofValid,
  );

  // Synchronize the resulting processSelection with the URL. We need to do this
  // in an effect; we cannot call updateSearchParams directly from the render.
  useEffect(() => {
    // If the agent report is not available yet, don't update the URL.
    if (agentReport == undefined) {
      return;
    }
    if (
      (processSelection == undefined) !=
        (state.processSelection == undefined) ||
      (processSelection != undefined &&
        state.processSelection != undefined &&
        JSON.stringify(processSelection.toRaw()) !=
          JSON.stringify(state.processSelection))
    ) {
      state.setProcessSelection(processSelection);
    }
  });

  const durationSeconds = state.logDuration ?? 5;

  async function onCapture(): Promise<void> {
    if (processSelection == undefined) {
      throw new Error("no processes selected");
    }

    const [env, selection] = processSelectionToGraph(processSelection.toRaw());
    setTraceInProgress(true);

    try {
      // Filter the pprof address overrides to only include selected processes.
      const selectedProcs = processSelection.selectedProcessTokens();
      const pprofOverrides = pprofInformation
        .validOverrideURLs()
        .filter((override) => selectedProcs.has(override.processToken));

      const {data, errors} = await client.mutate({
        mutation: CAPTURE_RECORDING,
        variables: {
          input: {
            durationSeconds,
            environment: env == UNNAMED_ENV ? null : env,
            selections: selection,
            eventsSpec: recordingType.eventLogs
              ? {
                  probeModulePackagePaths: state.selectedEvents
                    .filter((group) => group.type == "module")
                    .map((m) => m.pkgPath),
                  probePackages: state.selectedEvents
                    .filter((group) => group.type == "pkg")
                    .map((p) => p.packageName),
                  probeTypes: state.selectedEvents
                    .filter((group) => group.type == "type")
                    .map((t) => t.typeName),
                  probeFuncs: state.selectedEvents
                    .filter((group) => group.type == "func")
                    .map((f) => f.funcQualifiedName),
                  probeLines: state.selectedLineProbes.map(([func, line]) => {
                    const ev = findEvent(state.lineEvents, func, line);
                    if (ev == undefined) {
                      throw new Error("line event not found");
                    }
                    return lineEventToLineSpec(ev);
                  }),
                }
              : undefined,
            snapshotSpec: recordingType.snapshots
              ? {
                  captureSnapshot: true,
                }
              : undefined,
            executionTraceSpec:
              recordingType.shouldCollectExecutionTracesAndCpuProfiles()
                ? {
                    addressOverrides: pprofOverrides.map(
                      ({processToken, url}) => ({
                        processToken,
                        address: url,
                      }),
                    ),
                  }
                : undefined,
          },
        },
      });
      if (errors) {
        // This was not supposed to happen; the error was supposed to be thrown.
        // See:
        // https://community.apollographql.com/t/usesuspensequery-and-query-errors-confusion/6957/5?u=andreimatei
        throw errors;
      }

      const {recordingID, eventLogID, snapshotID, cpuProfileID} =
        data!.captureRecording;
      if (eventLogID) {
        toast("Events collection in progress.");
        navigate(`/recordings/${recordingID}/live-log/${eventLogID}`);
      } else if (snapshotID) {
        navigate(`/snapshots/${snapshotID}`);
      } else if (cpuProfileID) {
        navigate(`/cpuprofile/${cpuProfileID}`);
      } else {
        toast("Recording in progress.");
      }
    } catch (e) {
      toastError(e);
    }
    setTraceInProgress(false);
  }

  return (
    <>
      <Box my={3}>
        <Typography variant="h1">Capture Recording</Typography>
        <Typography variant="body3" color="primary.light">
          Choose what to include in the recording. If probes are selected, once
          the recording begins the generated events will be streamed, as well as
          saved for later analysis.
        </Typography>
      </Box>

      <Stack gap={3}>
        <Card>
          <CardHeader title="Select processes from which to capture" />
          <CardContent>
            <ProcessSelector
              selection={processSelection}
              agentReports={agentReport}
              onSelectionUpdated={(
                newSelection: RawProcessSelection | undefined,
              ) => {
                const procHierarchy: ProcessHierarchy | undefined = agentReport
                  ? new ProcessHierarchy(agentReport.Report.Reports)
                  : undefined;
                const procSelection: ProcessSelection | undefined =
                  ProcessSelection.hydrateFromRaw(newSelection, procHierarchy);
                state.setProcessSelection(procSelection);
              }}
              pprofInformation={
                state.captureExecutionTrace ? pprofInformation : undefined
              }
            />
            {(processSelection == undefined || processSelection.empty()) && (
              <Typography variant={"error"}>Selection required</Typography>
            )}
          </CardContent>
        </Card>
        <Card>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CardHeader title="Capture snapshot" />
            <Switch
              checked={state.captureSnapshot}
              onChange={(event) => {
                state.updateSearchParams({
                  captureSnapshot: event.target.checked,
                });
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CardHeader title="Capture execution trace and CPU profile" />
            <Switch
              checked={state.captureExecutionTrace}
              onChange={(event) => {
                state.updateSearchParams({
                  captureExecutionTrace: event.target.checked,
                });
              }}
            />
            {state.captureExecutionTrace &&
              processSelection &&
              !processSelection.empty() &&
              !pprofValid && (
                <Typography variant="error">
                  No processes have the pprof address configured; execution
                  traces and CPU profiles will not be collected.
                </Typography>
              )}
          </Stack>
        </Card>
        {processSelection == undefined ||
        processSelection.empty() ||
        processSelection.anyProcessesSupportEvents() ? (
          <EventsCard
            state={state}
            binariesForSelectedProcs={binariesForSelection}
            selectionRequired={!recordingType.something()}
          />
        ) : (
          <Card>
            <CardHeader
              title="Select probes to enable"
              subheader={
                "Probing not available because none of the selected " +
                "processes support it (the side-eye-go library does not currently " +
                "support probing)."
              }
            />
          </Card>
        )}
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography>Enable recording for</Typography>
          <TextField
            disabled={
              recordingType.something() && !recordingType.durationRecording()
            }
            sx={{width: "4em"}}
            color="secondary"
            value={durationSeconds}
            error={durationError}
            helperText={durationError ? "Invalid duration" : ""}
            onChange={(e) => {
              const valStr = e.target.value;
              const val = parseInt(valStr);
              if (isNaN(val)) {
                setDurationError(true);
              } else {
                setDurationError(false);
                state.updateSearchParams({logDuration: val});
              }
            }}
          />
          <Typography>seconds.</Typography>
        </Stack>
        <LoadingButton
          sx={{width: "fit-content"}}
          loadingPosition={"start"}
          startIcon={<SaveIcon />}
          loading={traceInProgress}
          variant={"contained"}
          onClick={() => void onCapture()}
          onAuxClick={() => void onCapture()}
          disabled={
            processSelection == undefined ||
            processSelection.empty() ||
            !recordingType.something() ||
            durationError
          }
        >
          {recordingType.recordingButtonLabel()}
        </LoadingButton>
      </Stack>
    </>
  );
}

function findEvent(
  lineEvents: LineEventType[],
  funcQualifiedName: string,
  line: number,
): LineEventType | undefined {
  return lineEvents.find(
    (ev) => ev.function.QualifiedName == funcQualifiedName && ev.line == line,
  );
}

function lineEventToLineSpec(ev: LineEventType): LineSpec {
  return {
    funcQualifiedName: ev.function.QualifiedName,
    line: ev.line,
    eventSpec: lineEventToEventSpec(ev),
  };
}
